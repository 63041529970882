<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/adminis' }">超级管理员</el-breadcrumb-item>
          <el-breadcrumb-item>新增管理员</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入电话"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model="form.sex" placeholder="请选择性别">
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属公司" prop="companyId">
            <el-select v-model="form.companyId" placeholder="请选择所属公司" @change="handlerSelect">
              <el-option
                v-for="item in tableData"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="菜单配置">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item title="公司菜单配置" name="1">
                <el-tree
                  ref="menuRefs"
                  :data="dataTree"
                  show-checkbox
                  node-key="id"
                  default-expand-all
                  :props="defaultProps">
                </el-tree>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>

          <el-form-item label="">
            <el-button type="primary" @click="onSubmit" size="small">确定</el-button>
            <el-button size="small" @click="$router.push('/adminis')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      rules: {
        companyId: [{ required: true, message: "必填项", trigger: "blur" }],
        name: [{ required: true, message: "必填项", trigger: "blur" }],
        phone: [{ required: true, message: "必填项", trigger: "blur" }],
        sex: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      tableData: [],
      activeNames: ['1'],
      dataTree: [],
      defaultProps: {
        children: 'childTree',
        label: 'title'
      }
    };
  },
  methods: {
    handleChange(val) {},
    loadCompany() {
      this.$ajax.get("companyQueryAll").then((res) => {
        this.tableData = res.data;
      });
    },
    handlerSelect(e) {
      this.loadTree(e)
    },
    loadTree(companyId) {
      this.$ajax.post('menuCompany', {
        id: companyId
      }).then(res=> {
        this.dataTree = res.data
      })
    },
    // queryAdmin
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let getCheckedKeys = this.$refs.menuRefs.getCheckedKeys()
          let getHalfCheckedKeys = this.$refs.menuRefs.getHalfCheckedKeys()
          getCheckedKeys = getCheckedKeys.concat(getHalfCheckedKeys)

          if(getCheckedKeys == '') {
            this.$message.error('请选择权限配置')
            return
          }
          let form = {
            companyId: this.form.companyId,
            name: this.form.name,
            phone: this.form.phone,
            sex: this.form.sex,
            menuIds: getCheckedKeys
          }

          console.log(form)
          this.$ajax.post("createAdmin", form).then((res) => {
            if (res.code == 0) {
              this.form = {};
              this.$message.success(res.msg);
              this.$router.push("/adminis");
            }
          });
        }
      });
    }
  },
  mounted() {
    this.loadCompany()
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  width: 500px;
}
.el-collapse {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  overflow: hidden;
}
.el-collapse-item {
  box-shadow: 0px 0px 0px rgba(211, 211, 211, 0.5);
  border: 0px solid #eee;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
}
/deep/.el-collapse-item__header {
  height: 42px;
  padding: 0 10px;
  border-bottom: 0;
}
/deep/.el-tree-node__label {
  font-size: 15px;
  line-height: 10px;
  letter-spacing: 2px;
}
/deep/.el-tree-node__content {
  height: 35px;
}
.el-select {
  width: 100%;
}
</style>
